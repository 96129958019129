import Popup from '@/components/shared/Popup';
import { m } from 'framer-motion';
import { useEffect, useRef, useState, MouseEvent } from 'react';

import { useIsVideoPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import classNames from 'classnames';
import Button from '@/components/shared/Button';
import PlaySVG from '@/svg/play.svg';
import PauseSVG from '@/svg/pause.svg';
import OnFullscreenSVG from '@/svg/on-fullscreen.svg';
import OffFullscreenSVG from '@/svg/off-fullscreen.svg';
import OnSoundSVG from '@/svg/on-sound.svg';
import OffSoundSVG from '@/svg/off-sound.svg';
import { videoLazyload } from '@/lazy-load';
import { useSetVideoFullscreenState, useVideoFullscreenValue } from '@/atoms/video-fullscreen';

interface Props {}

export const VIDEO_POPUP_NAME = 'video';
const TIME_TO_IDLE = 2;

const VideoPopup = ({}: Props) => {
    const { closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsVideoPopupOpenedValue();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isIdle, setIsIdle] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const isVideoFullscreen = useVideoFullscreenValue();
    const setVideoFullscreen = useSetVideoFullscreenState();
    const videoRef = useRef<HTMLVideoElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);
    const controlsElRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(100);

    useEffect(() => {
        if (isOpened) {
            setIsIdle(false);
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
            setVideoFullscreen(false);
        }
    }, [isOpened, setVideoFullscreen]);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const updateProgress = () => {
                setCurrentTime(video.currentTime);
                setDuration(video.duration);
            };

            video.addEventListener('timeupdate', updateProgress);
            return () => {
                video.removeEventListener('timeupdate', updateProgress);
            };
        }
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        const popup = popupRef.current;

        const setTimer = () => {
            timeoutRef.current = setTimeout(() => {
                setIsIdle(true);
            }, 1000 * TIME_TO_IDLE);
        };

        const resetTimer = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            setIsIdle(false);
            setTimer();
        };

        const onEnded = () => {
            setIsPlaying(false);
        };

        if (video) {
            if (isPlaying) {
                video.play();

                if (popup) {
                    popup.addEventListener('mousemove', resetTimer);
                    popup.addEventListener('click', resetTimer);
                    popup.addEventListener('touchstart', resetTimer);
                    popup.addEventListener('keypress', resetTimer);
                    setTimer();
                }

                video.addEventListener('ended', onEnded);
            } else {
                video.pause();

                if (popup) {
                    popup.removeEventListener('mousemove', resetTimer);
                    popup.removeEventListener('click', resetTimer);
                    popup.removeEventListener('touchstart', resetTimer);
                }

                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                setIsIdle(false);

                video.removeEventListener('ended', onEnded);
            }
        }

        return () => {
            if (video) {
                video.removeEventListener('ended', onEnded);
            }
            if (popup) {
                popup.removeEventListener('mousemove', resetTimer);
                popup.removeEventListener('click', resetTimer);
                popup.removeEventListener('touchstart', resetTimer);
            }
            setIsIdle(false);
        };
    }, [isPlaying]);

    function onTimelineBarClick(event: MouseEvent<HTMLButtonElement>) {
        const video = videoRef.current;
        const target = event.target as HTMLDivElement;
        const rect = target.getBoundingClientRect();
        const fraction = (event.clientX - rect.left) / rect.width;
        const seconds = Math.max(0.01, Math.round(duration * fraction));

        if (video) {
            video.currentTime = seconds;
        }
    }

    function formatTime(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    return (
        <Popup
            name={VIDEO_POPUP_NAME}
            className={classNames('video-popup', {
                'is-fullscreen': isVideoFullscreen,
            })}
            overlay
        >
            <m.div
                className={classNames('video-popup__inner', {
                    'review-card--idle': isIdle,
                })}
                variants={{
                    visible: {
                        transition: {
                            duration: 0.5,
                        },
                        opacity: 1,
                        scale: 1,
                    },
                    hidden: {
                        opacity: 0,
                        scale: 0.8,
                    },
                }}
                ref={popupRef}
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
            >
                <div className="video-popup__video">
                    <video playsInline ref={videoRef} muted={isMuted}>
                        <source src="/videos/main-detail-video-4.mp4" type="video/mp4"></source>
                    </video>
                </div>
                <Button
                    geometryVariant="square-bracket"
                    onClick={() => {
                        closePopup(VIDEO_POPUP_NAME);
                    }}
                    aria-label="Закрыть фильтры"
                    className="video-popup-close close-button"
                    size="default"
                >
                    X
                </Button>
                <div className="video-player__player">
                    <Button
                        geometryVariant="mustache"
                        icon={isPlaying ? <PauseSVG /> : <PlaySVG />}
                        className="video-player__play"
                        onClick={() => {
                            videoLazyload?.update();
                            setIsPlaying((prev) => !prev);
                        }}
                    />

                    <div ref={controlsElRef} className="video-player-controls">
                        <div className="video-player-controls__top m-text-xs">
                            {currentTime && (
                                <div className="video-player-controls__top-left">{formatTime(currentTime)}</div>
                            )}
                            {duration && <div className="video-player-controls__top-right">{formatTime(duration)}</div>}
                        </div>
                        <button
                            className="video-player-controls__timeline"
                            onClick={onTimelineBarClick}
                            aria-label="Быстро перейти на фрагмент"
                        >
                            <span
                                className="video-player-controls__timeline-el"
                                style={{
                                    transform: `scaleX(${currentTime / duration})`,
                                }}
                            ></span>
                            {duration && (
                                <span
                                    className="video-player-controls__timeline-arrow"
                                    style={{
                                        transform: `translate(${
                                            (currentTime / duration) * (controlsElRef.current?.clientWidth ?? 0)
                                        }px, 0)`,
                                    }}
                                ></span>
                            )}
                        </button>
                    </div>
                </div>

                <div className="video-popup-btns_wrapper">
                    <Button
                        geometryVariant="mustache"
                        icon={isMuted ? <OffSoundSVG /> : <OnSoundSVG />}
                        className="video-popup-sound"
                        onClick={() => {
                            setIsMuted((prev) => !prev);
                        }}
                    />
                    <Button
                        geometryVariant="mustache"
                        icon={isVideoFullscreen ? <OffFullscreenSVG /> : <OnFullscreenSVG />}
                        className="video-popup-fullscreen"
                        onClick={() => {
                            setVideoFullscreen((prev) => !prev);
                        }}
                    />
                </div>
            </m.div>
        </Popup>
    );
};

export default VideoPopup;
