import { forwardRef, useEffect, FormEvent, useState } from 'react';
import Input from '../Input';
import { Props as InputProps } from '../Input/Input';
import { clamp } from '@/utils/clamp';
import classNames from 'classnames';

interface Props extends InputProps {
    min: number;
    max: number;
    value: number;
    defaultValue: number;
    prefix?: string;
    suffix?: string;
    withDots?: boolean;
    handleChange: (value: number) => void;
    handleBlur: (value: number) => void;
}

const formatNumberWithDots = (value: number): string => new Intl.NumberFormat('de-DE').format(value);

const parseFormattedNumber = (value: string): number => parseInt(value.replace(/\./g, ''), 10);

const TextInput = forwardRef<HTMLInputElement, Props>(
    (
        { value, min, max, defaultValue, prefix = '', suffix = '', handleChange, handleBlur, withDots, ...props },
        ref,
    ) => {
        const [currentValue, setCurrentValue] = useState<number | ''>(defaultValue);
        const [isFocused, setIsFocused] = useState(false);

        useEffect(() => {
            if (!isNaN(value)) {
                setCurrentValue(value);
            }
        }, [value]);

        return (
            <label
                className={classNames('range-slider-text', {
                    'is-focused': isFocused,
                })}
            >
                <span className="range-slider-info__bracket range-slider-info__bracket--left">{'['}</span>&nbsp;
                <Input
                    {...props}
                    ref={ref}
                    tag="span"
                    value={
                        withDots && !isFocused
                            ? typeof currentValue === 'string'
                                ? ''
                                : formatNumberWithDots(currentValue)
                            : currentValue
                    }
                    prefix={!isFocused ? prefix : ''}
                    suffix={!isFocused ? suffix : ''}
                    className="range-slider-text-input"
                    onBlur={(event: FormEvent) => {
                        if (currentValue !== '') {
                            const clampedValue = clamp(currentValue, min, max);
                            setCurrentValue(clampedValue);
                            handleBlur(clampedValue);
                        } else {
                            setCurrentValue(defaultValue);
                            handleBlur(defaultValue);
                        }
                        setIsFocused(false);
                    }}
                    onChange={(event: FormEvent) => {
                        const eventTarget = event.target as HTMLInputElement;
                        if (eventTarget) {
                            const rawValue = eventTarget.value.replace(prefix, '').replace(suffix, '');
                            const parsedValue = parseFormattedNumber(rawValue);

                            if (!isNaN(parsedValue)) {
                                handleChange(parsedValue);
                                setCurrentValue(parsedValue);
                            } else {
                                setCurrentValue('');
                            }
                        }
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    style={{
                        width: `${currentValue ? clamp(currentValue.toString().length + prefix.length + suffix.length + 1, 5, 10) : 5}ch`,
                    }}
                />
                &nbsp;
                <span className="range-slider-info__bracket range-slider-info__bracket--right">{']'}</span>
            </label>
        );
    },
);

TextInput.displayName = 'TextInput';

export default TextInput;
