import { PresetFilterField, PresetFilterItem } from '@/types';
import ArrowMoreSVG from '@/svg/arrow-more.svg';
import RangeSlider from '@/components/shared/RangeSlider';
import { useEffect, useState } from 'react';
import PresetFiltersList from './PresetFiltersList';
import Collapse from '@/components/shared/Collapse';
import { FILTER_COLLAPSE_DURATION } from './FiltersPopup';
import classNames from 'classnames';
import { useDiameterFiltersValue } from '@/atoms/catalog/diameterFilters';

let timer: NodeJS.Timeout | null;

interface Props {
    defaultValue: { min: number | null; max: number | null };
    stateMinValue?: number;
    stateMaxValue?: number;
    presetFilters: PresetFilterItem[];
    type: PresetFilterField;
    label: string;
    subLabel: string;
    onUpdate: (...args: any) => void;
    onScroll: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    prefix?: string;
    suffix?: string;
    step?: number;
}

const DoubleFiltersItem = ({
    defaultValue,
    stateMinValue,
    stateMaxValue,
    presetFilters,
    label,
    subLabel,
    type,
    onScroll,
    onUpdate,
    prefix,
    suffix,
    step = 10,
}: Props) => {
    const [isOpened, setIsOpened] = useState(false);
    const [currentValue, setCurrentValue] = useState<{ minValue: number | null; maxValue: number | null }>({
        minValue: stateMinValue ?? null,
        maxValue: stateMaxValue ?? null,
    });
    const diameterFilters = useDiameterFiltersValue();
    const currentPrefilters =
        type === 'diameter' && diameterFilters.filters.length > 0 ? diameterFilters.filters : presetFilters;
    const currentSublabel = type === 'diameter' ? diameterFilters.label : subLabel;

    useEffect(() => {
        if (stateMinValue !== undefined && stateMaxValue !== undefined) {
            setCurrentValue({
                minValue: stateMinValue,
                maxValue: stateMaxValue,
            });
        }
    }, [stateMaxValue, stateMinValue]);

    const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        isOpened
            ? timer
                ? (clearTimeout(timer), (timer = null))
                : (timer = setTimeout(() => {
                      setIsOpened(false);
                      timer = null;
                  }, FILTER_COLLAPSE_DURATION))
            : setIsOpened(true);

        onScroll(event);
    };

    return (
        <>
            <Collapse collapsed>
                <div className="input-group">
                    <label className="filter-row__name m-text-xs">{label}</label>
                    <Collapse.Toggler
                        className="select-toggler js-select-toggler-first"
                        onToggle={onClickHandler}
                        data-dropdown-group={type}
                    >
                        <ArrowMoreSVG />
                    </Collapse.Toggler>
                </div>
                <Collapse.Content>
                    <div className="filter-row">
                        <div className="filter-row__list">
                            <RangeSlider
                                min={defaultValue.min ?? 0}
                                max={defaultValue.max ?? 0}
                                currentMin={currentValue.minValue ?? stateMinValue}
                                currentMax={currentValue.maxValue ?? stateMaxValue}
                                step={step}
                                prefix={prefix}
                                suffix={suffix}
                                isPrice={type === 'price'}
                                onChange={onUpdate}
                            />
                        </div>
                    </div>
                </Collapse.Content>
            </Collapse>
            {currentPrefilters.length > 0 && (
                <Collapse collapsed>
                    <div className="input-group">
                        <label className="filter-row__name m-text-xs">{currentSublabel}</label>
                        <Collapse.Toggler
                            className={classNames('select-toggler js-select-toggler-second', {
                                'js-select-toggler--diameter': type === 'diameter',
                            })}
                            onToggle={onClickHandler}
                            data-dropdown-group={type}
                        >
                            <ArrowMoreSVG />
                        </Collapse.Toggler>
                    </div>
                    <Collapse.Content>
                        {isOpened && (
                            <div className="filter-row js-second-filter-row">
                                <PresetFiltersList
                                    presetFilters={currentPrefilters}
                                    type={type}
                                    defaultValue={defaultValue}
                                    setCurrentValue={setCurrentValue}
                                />
                            </div>
                        )}
                    </Collapse.Content>
                </Collapse>
            )}
        </>
    );
};

export default DoubleFiltersItem;
