import { Brand } from '@/api/brands';
import { Material } from '@/api/materials';
import Button from '@/components/shared/Button';
import Popup from '@/components/shared/Popup';
import { m } from 'framer-motion';
import { CSSProperties, useMemo, useState } from 'react';
import { useIsFiltersPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import RangeSlider from '@/components/shared/RangeSlider/RangeSlider';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { Filters, useFiltersState } from '@/atoms/catalog/filters-state';
import useDebouncedFunction from '@/hooks/use-debounced-function';
import { produce } from 'immer';
import ArrowMoreSVG from '@/svg/arrow-more.svg';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { removeNullishAndUndefinedFromObject } from '@/utils/objects/index';
import { AllFiltersItems, AllFiltersType, FilterList, ImageShape, PresetFilterItem, ProductState } from '@/types';
import { FilterFields } from '@/api/productFilters';
import { stateDict } from '@/dicts';
import { addUniqueItemToArray } from '@/utils/arrays/add-unique-item-to-array';
import AllFilters from './AllFilters';
import { useAllFiltersWidthValue, useSetAllFiltersWidthState } from '@/atoms/catalog/all-filters-width';
import { useAllFiltersShowState } from '@/atoms/catalog/all-filters';
import FiltersItem from './FiltersItem';
import DoubleFiltersItem from './DoubleFiltersItem';
import FiltersSizesItem from './FiltersSizesItem';
import Collapse from '@/components/shared/Collapse';

interface Props {
    brands: Brand[];
    materials: Material[];
    filters: Partial<FilterFields>;
    presetFilters: PresetFilterItem[];
}

export const FILTER_COLLAPSE_DURATION = 550;
export const FILTERS_POPUP_NAME = 'catalog-filters';
export const filtersToShowLength = 13;

const clearFilters = (obj: Filters) => {
    const newObj = { ...obj };

    for (const key in newObj) {
        const _key = key as keyof Filters;

        if (Array.isArray(newObj[_key])) {
            newObj[_key as AllFiltersType] = [];
        } else {
            newObj[_key] = undefined;
        }
        if (_key === 'category') {
            newObj['category'] = obj['category'];
        }
        if (_key === 'search') {
            newObj['search'] = obj['search'];
        }
    }

    document.dispatchEvent(new Event('clear-filters'));

    return newObj;
};

const openDoubleDropdown = (el: HTMLButtonElement) => {
    const togglerGroup = el.closest('[data-dropdown-group]')?.getAttribute('data-dropdown-group');

    if (togglerGroup) {
        const firstToggler = el.closest('.js-select-toggler-first');
        const secondToggler = el.closest('.js-select-toggler-second');

        if (firstToggler) {
            const secondToggler = document.querySelector<HTMLButtonElement>(
                `.js-select-toggler-second[data-dropdown-group='${togglerGroup}']`,
            );
            secondToggler?.click();
        }

        if (secondToggler) {
            const firstToggler = document.querySelector<HTMLButtonElement>(
                `.js-select-toggler-first[data-dropdown-group='${togglerGroup}']`,
            );
            firstToggler?.click();
        }
    }
};

const scrollDropdown = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (!(event.currentTarget instanceof HTMLButtonElement)) return;

    const targetElement = event.currentTarget;
    openDoubleDropdown(targetElement);

    setTimeout(() => {
        const openedCollapse = targetElement.closest<HTMLDivElement>('.collapse--opened');
        if (!openedCollapse) return;

        const openedRow = openedCollapse?.lastChild?.firstChild;
        const footer = document.querySelector<HTMLDivElement>('.js-filters-footer');

        if (openedRow instanceof HTMLElement) {
            const footerRect = footer?.getBoundingClientRect();
            const rowRect = openedRow.getBoundingClientRect();

            if (footerRect && footerRect.top < rowRect.bottom) {
                openedRow.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
            }
        }
    }, FILTER_COLLAPSE_DURATION);
};

const FiltersPopup = ({ brands, materials, filters, presetFilters }: Props) => {
    const router = useRouter();
    const { closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsFiltersPopupOpenedValue();
    const [moreBtn, setMoreBtn] = useState<HTMLButtonElement | null>(null);
    const [activeFilterList, setActiveFilterList] = useState<FilterList>({
        type: 'brands[]',
        filters: [],
    });

    const [showAllFilters, setShowAllFilters] = useAllFiltersShowState();
    const [filtersState, setFiltersState] = useFiltersState();
    const filtersWidth = useAllFiltersWidthValue();
    const setWidth = useSetAllFiltersWidthState();

    const defaultPrice = { min: filters.priceMin ?? 0, max: filters.priceMax ?? 0 };
    const defaultPower = { min: filters.powerMin ?? 0, max: filters.powerMax ?? 0 };
    const defaultDiameters = { min: filters.diameterMin ?? 0, max: filters.diameterMax ?? 0 };
    const productPrices = useMemo(() => presetFilters.filter((filter) => filter.field === 'price'), [presetFilters]);
    const productDiameters = useMemo(
        () => presetFilters.filter((filter) => filter.field === 'diameter' && !filter.shape_id),
        [presetFilters],
    );
    const productTypes = filters.type ?? [];
    const productInserts = filters.insert ?? [];
    const productColors = filters.color ?? [];
    const productCollection = filters.collection ?? [];
    const productDialColors = filters.dial_color ?? [];
    const bracelets = filters.bracelet ?? [];
    const mechanisms = filters.mechanism ?? [];
    const states = filters.state ?? [];
    const options = filters.options ?? [];
    const forms = useMemo(
        () =>
            (filters.shapes ?? []).map<{
                id: number;
                name: string;
                fieldName: keyof Filters | (keyof Filters)[];
                fieldLabel: string;
                is_standard: boolean;
                sizeList: PresetFilterItem[];
                image?: ImageShape;
            }>((shape) => ({
                id: shape.id,
                name: shape.name,
                is_standard: shape.is_standard,
                sizeList: presetFilters.filter((filter) => filter.field === 'diameter' && filter.shape_id === shape.id),
                fieldName: ['diameterMin', 'diameterMax'],
                fieldLabel: shape.is_standard ? 'Стандартные размеры' : 'Нестандартные размеры',
                image: shape.image,
            })),
        [filters.shapes, presetFilters],
    );
    const genders = filters.genders ?? [];

    const filtersNames: Record<AllFiltersType, AllFiltersItems> = {
        'brands[]': brands,
        'materials[]': materials,
        'bracelets[]': bracelets,
        'collection[]': productCollection,
        'mechanisms[]': mechanisms,
        'dial_color[]': productDialColors,
        'type[]': productTypes,
        'insert[]': productInserts,
        'color[]': productColors,
        'genders[]': genders,
        'options[]': options,
    };

    const updatePrice = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.priceMin = min;
                draft.priceMax = max;
            }),
        );
    }, 300);

    const updateDiameter = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.diameterMin = min;
                draft.diameterMax = max;
            }),
        );
    }, 300);

    const updatePower = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.powerMin = min;
                draft.powerMax = max;
            }),
        );
    }, 300);

    const openMoreBtn = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        const targetElement = event.currentTarget;
        const currentMoreBtn = targetElement.closest<HTMLButtonElement>('.js-more-btn');
        const btnType = currentMoreBtn?.getAttribute('data-type') as AllFiltersType;

        if (!btnType) return;

        if (moreBtn !== currentMoreBtn) {
            const filterItems = filtersNames[btnType];

            const updateFilters = () => {
                setActiveFilterList({
                    type: btnType,
                    filters: filterItems,
                });
                setTimeout(() => {
                    setShowAllFilters(true);
                    setTimeout(() => {
                        const inner = document.querySelector<HTMLDivElement>('.js-all-brands-width');
                        if (inner) {
                            setWidth(inner.offsetWidth);
                        }
                    }, 50);
                }, 200);
            };

            if (showAllFilters) {
                setShowAllFilters(false);
                setTimeout(updateFilters, 400);
            } else {
                updateFilters();
            }

            setMoreBtn(currentMoreBtn);
        } else {
            setShowAllFilters((prev) => !prev);
        }
    };

    return (
        <Popup
            name={FILTERS_POPUP_NAME}
            className={classNames('filters-popup', {
                'all-filters--opened': showAllFilters,
            })}
            overlay
        >
            <m.div
                className="filters-popup__inner"
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.07,
                            duration: 0.5,
                        },
                        opacity: 1,
                        y: 0,
                    },
                    hidden: {
                        opacity: 0,
                        y: 20,
                    },
                }}
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
                style={
                    {
                        '--all-filters-width': `${filtersWidth}px`,
                    } as CSSProperties
                }
            >
                <div className="filters-popup__main">
                    <div className="catalog-popup-header">
                        <div className="catalog-popup-title text-xs">Фильтры</div>
                        <Button
                            geometryVariant="square-bracket"
                            onClick={() => {
                                closePopup(FILTERS_POPUP_NAME);
                            }}
                            aria-label="Закрыть фильтры"
                            className="catalog-popup-close close-button"
                        >
                            X
                        </Button>
                    </div>
                    <div className="filters-body">
                        {productTypes && productTypes.length > 0 && (
                            <FiltersItem
                                filters={productTypes}
                                type="type[]"
                                label="Тип"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {brands && brands.length > 0 && (
                            <FiltersItem
                                filters={brands}
                                type="brands[]"
                                label="Бренд"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {defaultPrice && defaultPrice.min !== defaultPrice.max && (
                            <DoubleFiltersItem
                                defaultValue={defaultPrice}
                                stateMinValue={filtersState.priceMin}
                                stateMaxValue={filtersState.priceMax}
                                presetFilters={productPrices}
                                type="price"
                                label="Цена"
                                subLabel="Популярные цены"
                                onUpdate={updatePrice}
                                onScroll={scrollDropdown}
                                prefix="$ "
                            />
                        )}

                        <Collapse collapsed>
                            <div className="input-group">
                                <label className="filter-row__name m-text-xs">Наличие</label>
                                <Collapse.Toggler className="select-toggler" onToggle={scrollDropdown}>
                                    <ArrowMoreSVG />
                                </Collapse.Toggler>
                            </div>
                            <Collapse.Content>
                                <div className="filter-row">
                                    <div className="filter-row__list">
                                        <FilterCheckbox
                                            name="available"
                                            value="1"
                                            type="radio"
                                            checked={
                                                typeof filtersState.available !== 'undefined' &&
                                                filtersState.available === 1
                                            }
                                            onChange={(arg) => {
                                                setFiltersState(
                                                    produce((draft) => {
                                                        if (arg) {
                                                            draft.available = 1;
                                                        } else {
                                                            draft.available = undefined;
                                                        }
                                                    }),
                                                );
                                            }}
                                        >
                                            в наличии
                                        </FilterCheckbox>
                                        <FilterCheckbox
                                            name="available"
                                            value="0"
                                            type="radio"
                                            checked={
                                                typeof filtersState.available !== 'undefined' &&
                                                filtersState.available === 0
                                            }
                                            onChange={(arg) => {
                                                setFiltersState(
                                                    produce((draft) => {
                                                        if (arg) {
                                                            draft.available = 0;
                                                        } else {
                                                            draft.available = undefined;
                                                        }
                                                    }),
                                                );
                                            }}
                                        >
                                            под заказ
                                        </FilterCheckbox>
                                    </div>
                                </div>
                            </Collapse.Content>
                        </Collapse>

                        {states && states.length > 0 && (
                            <Collapse collapsed>
                                <div className="input-group">
                                    <label className="filter-row__name m-text-xs">Состояние</label>
                                    <Collapse.Toggler className="select-toggler" onToggle={scrollDropdown}>
                                        <ArrowMoreSVG />
                                    </Collapse.Toggler>
                                </div>
                                <Collapse.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {states.map((state: ProductState) => (
                                                <FilterCheckbox
                                                    key={state}
                                                    name={stateDict[state]}
                                                    value={state.toString()}
                                                    type="checkbox"
                                                    checked={(filtersState['state[]'] || []).includes(state)}
                                                    onChange={(arg) => {
                                                        setFiltersState(
                                                            produce((draft) => {
                                                                if (arg) {
                                                                    draft['state[]'] = addUniqueItemToArray(
                                                                        draft['state[]'] || [],
                                                                        state,
                                                                    );
                                                                } else {
                                                                    draft['state[]'] = (draft['state[]'] || []).filter(
                                                                        (item) => item !== state,
                                                                    );
                                                                }
                                                            }),
                                                        );
                                                    }}
                                                >
                                                    {stateDict[state]}
                                                </FilterCheckbox>
                                            ))}
                                        </div>
                                    </div>
                                </Collapse.Content>
                            </Collapse>
                        )}

                        {defaultDiameters &&
                            defaultDiameters.min !== defaultDiameters.max &&
                            forms &&
                            forms.length > 0 && (
                                <FiltersSizesItem
                                    label="Форма часов"
                                    onScroll={scrollDropdown}
                                    onOpenPopup={openMoreBtn}
                                    presetFilters={productDiameters}
                                    forms={forms}
                                    defaultValue={defaultDiameters}
                                    stateMinValue={filtersState.diameterMin}
                                    stateMaxValue={filtersState.diameterMax}
                                />
                            )}

                        {defaultDiameters && defaultDiameters.min !== defaultDiameters.max && (
                            <DoubleFiltersItem
                                defaultValue={defaultDiameters}
                                stateMinValue={filtersState.diameterMin}
                                stateMaxValue={filtersState.diameterMax}
                                presetFilters={productDiameters}
                                type="diameter"
                                label="Диаметр"
                                subLabel="Популярные размеры"
                                onUpdate={updateDiameter}
                                onScroll={scrollDropdown}
                                suffix=" мм"
                                step={1}
                            />
                        )}

                        {productDialColors && productDialColors.length > 0 && (
                            <FiltersItem
                                filters={productDialColors}
                                type="dial_color[]"
                                label="Цвет циферблата"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {materials && materials.length > 0 && (
                            <FiltersItem
                                filters={materials}
                                type="materials[]"
                                label="Материал"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {genders && genders.length > 0 && (
                            <FiltersItem
                                filters={genders}
                                type="genders[]"
                                label="Пол"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {productCollection && productCollection.length > 0 && (
                            <FiltersItem
                                filters={productCollection}
                                type="collection[]"
                                label="Коллекция"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {bracelets && bracelets.length > 0 && (
                            <FiltersItem
                                filters={bracelets}
                                type="bracelets[]"
                                label="Браслет"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {defaultPower && defaultPower.min !== defaultPower.max && (
                            <Collapse collapsed>
                                <div className="input-group">
                                    <label className="filter-row__name m-text-xs">Запас хода</label>
                                    <Collapse.Toggler className="select-toggler" onToggle={scrollDropdown}>
                                        <ArrowMoreSVG />
                                    </Collapse.Toggler>
                                </div>
                                <Collapse.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            <RangeSlider
                                                min={defaultPower.min}
                                                max={defaultPower.max}
                                                currentMin={filtersState.priceMin}
                                                currentMax={filtersState.priceMax}
                                                step={10}
                                                suffix=" Ч"
                                                onChange={updatePower}
                                            />
                                        </div>
                                    </div>
                                </Collapse.Content>
                            </Collapse>
                        )}

                        {options && options.length > 0 && (
                            <FiltersItem
                                filters={options}
                                type="options[]"
                                label="Функции"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {mechanisms && mechanisms.length > 0 && (
                            <FiltersItem
                                filters={mechanisms}
                                type="mechanisms[]"
                                label="Механизмы"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {productInserts && productInserts.length > 0 && (
                            <FiltersItem
                                filters={productInserts}
                                type="insert[]"
                                label="Вставка"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}

                        {productColors && productColors.length > 0 && (
                            <FiltersItem
                                filters={productColors}
                                type="color[]"
                                label="Цвет"
                                onScroll={scrollDropdown}
                                onOpenPopup={openMoreBtn}
                            />
                        )}
                    </div>
                </div>

                <div className="filters-footer js-filters-footer">
                    <Button
                        geometryVariant="mustache"
                        onClick={() => {
                            const { search } = router.query;
                            router.push(
                                {
                                    pathname: router.pathname,
                                    query: {
                                        ...removeNullishAndUndefinedFromObject(clearFilters(router.query)),
                                        page: 1,
                                        ...(search ? { search } : {}),
                                    },
                                },
                                undefined,
                                { shallow: true },
                            );
                            setFiltersState(clearFilters(filtersState));
                            closePopup(FILTERS_POPUP_NAME);
                        }}
                    >
                        Сбросить
                    </Button>
                    <Button
                        geometryVariant="mustache"
                        onClick={() => {
                            const { search } = router.query;
                            router.push(
                                {
                                    pathname: router.pathname,
                                    query: removeNullishAndUndefinedFromObject({
                                        ...router.query,
                                        ...filtersState,
                                        sort: router.query.sort,
                                        order: router.query.order,
                                        page: 1,
                                        search,
                                    }),
                                },
                                undefined,
                                { shallow: true },
                            );
                            closePopup(FILTERS_POPUP_NAME);
                        }}
                    >
                        Принять
                    </Button>
                </div>

                <AllFilters filterList={activeFilterList} />
            </m.div>
        </Popup>
    );
};

export default FiltersPopup;
