import ArrowMoreSVG from '@/svg/arrow-more.svg';
import { ImageShape, PresetFilterItem } from '@/types';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Filters, useFiltersState } from '@/atoms/catalog/filters-state';
import Collapse from '@/components/shared/Collapse';
import { useSetDiameterFiltersState } from '@/atoms/catalog/diameterFilters';
import { produce } from 'immer';

type Form = {
    id: number;
    name: string;
    fieldName: keyof Filters | (keyof Filters)[];
    fieldLabel: string;
    is_standard: boolean;
    sizeList: PresetFilterItem[];
    image?: ImageShape;
};

type Props = {
    label: string;
    onScroll: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    onOpenPopup: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    presetFilters: PresetFilterItem[];
    forms: Form[];
    defaultValue: {
        min: number | null;
        max: number | null;
    };
    stateMinValue?: number;
    stateMaxValue?: number;
};

const FiltersSizesItem = ({
    label,
    onScroll,
    onOpenPopup,
    forms,
    presetFilters,
    defaultValue,
    stateMinValue,
    stateMaxValue,
    ...props
}: Props) => {
    const dependentItemRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState<string | null>(null);
    const [, setFiltersState] = useFiltersState();
    const setDiameterFilters = useSetDiameterFiltersState();

    const onChangeFormHandler = (form: Form) => {
        const diameterToggler = document.querySelector<HTMLDivElement>('.js-select-toggler--diameter');
        const collapse = diameterToggler?.closest('.collapse');
        const isSameForm = selected === form.name;

        if (collapse) {
            const content = collapse.querySelector<HTMLDivElement>('.collapse__content');
            const filterRow = collapse.querySelector<HTMLDivElement>('.js-second-filter-row');
            const isOpen = collapse.classList.contains('collapse--opened');

            const updateFilters = () => {
                setDiameterFilters({
                    label: 'Популярные размеры',
                    filters: isSameForm ? presetFilters : form.sizeList,
                });
            };

            const adjustContentHeight = () => {
                if (content && filterRow) {
                    content.style.height = filterRow.scrollHeight + 'px';
                }
            };

            if (!isSameForm && !isOpen) diameterToggler?.click();

            updateFilters();

            setTimeout(adjustContentHeight, 50);

            setSelected(isSameForm ? null : form.name);
        }
        setFiltersState(
            produce((draft) => {
                isSameForm ? (draft['shapes[]'] = []) : (draft['shapes[]'] = [form.id]);
            }),
        );
    };

    useEffect(() => {
        if (dependentItemRef.current) {
            dependentItemRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selected]);

    useEffect(() => {
        setFiltersState((prevFiltersState) => {
            const newFiltersState = { ...prevFiltersState };

            forms.forEach((form) => {
                if (Array.isArray(form.fieldName)) {
                    form.fieldName.forEach((fieldName) => {
                        if (fieldName.endsWith('[]')) {
                            (newFiltersState as any)[fieldName] = [];
                        } else {
                            (newFiltersState as any)[fieldName] = null;
                        }
                    });
                } else {
                    if (typeof form.fieldName === 'string') {
                        if (form.fieldName.endsWith('[]')) {
                            (newFiltersState as any)[form.fieldName] = [];
                        } else {
                            (newFiltersState as any)[form.fieldName] = null;
                        }
                    }
                }
            });

            return newFiltersState;
        });
    }, [forms, selected, setFiltersState]);

    return (
        <>
            <Collapse className="select" {...props}>
                <div className="input-group js-input-group filters-sizes-item">
                    <label className="filter-row__name m-text-xs">{label}</label>
                    <Collapse.Toggler className="form-control select-toggler" onToggle={onScroll}>
                        <ArrowMoreSVG />
                    </Collapse.Toggler>
                </div>
                <Collapse.Content>
                    <div className="filter-row">
                        <div className="filters-sizes-item-buttons-list">
                            {forms.map((form, i) => (
                                <button
                                    key={i}
                                    onClick={() => onChangeFormHandler(form)}
                                    aria-label={form.name}
                                    className={classNames('filters-sizes-item-button', {
                                        'is-active': selected === form.name,
                                    })}
                                >
                                    {form.image?.src && (
                                        <Image
                                            src={form.image.src}
                                            width={form.image.width}
                                            height={form.image.height}
                                            alt={form.name || ''}
                                            className="img-fluid filters-sizes-item-button__img"
                                            unoptimized={form.image.src.endsWith('.svg')}
                                        />
                                    )}
                                    <span className="filters-sizes-item-button__text m-text-xs">{form.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </Collapse.Content>
            </Collapse>
        </>
    );
};

export default FiltersSizesItem;
